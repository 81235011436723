import React, { useState } from 'react';
import BingoItem from './BingoItem';
import Modal from 'react-modal';
import './BingoBoard.css';

Modal.setAppElement(document.getElementById('App'));

function BingoBoard(props) {
  const [modalIsOpen,setIsOpen] = useState(false);
  const clicked = [];  
  
  function handleClick(num) {
    clicked.push(num);
    console.log(clicked);
    if ((clicked.includes(0) && clicked.includes(3) && clicked.includes(6)) ||
        (clicked.includes(1) && clicked.includes(4) && clicked.includes(7)) ||
        (clicked.includes(2) && clicked.includes(5) && clicked.includes(8)) ||
        (clicked.includes(0) && clicked.includes(1) && clicked.includes(2)) ||
        (clicked.includes(3) && clicked.includes(4) && clicked.includes(5)) ||
        (clicked.includes(6) && clicked.includes(7) && clicked.includes(8)) ||
        (clicked.includes(0) && clicked.includes(4) && clicked.includes(8)) ||
        (clicked.includes(2) && clicked.includes(4) && clicked.includes(6))) {
        console.log("BINGO");
        setIsOpen(true);
    }
  };

  console.log(props);
  
  return(
    <div id='bingoboard'>
      <Modal isOpen={modalIsOpen} contentLabel="BINGO!">
        <div className="modalRoot">
          <h2>Bingo!</h2>
          <p>Du darfst an der nächsten Videokonferenz ohne Beinkleid teilnehmen!</p>
          <a href="/">Nochmal?</a>
        </div>
      </Modal>

      { props.bingoItemValues && props.bingoItemValues.map((item, idx) => {
        return(
          <BingoItem idx={idx} key={idx} item={item} add={handleClick} />
        )
      }) };
    </div>
  )
}

export default BingoBoard;