import React, { useState } from 'react';
import './BingoItem.css';
import Hyphenated from 'react-hyphen';
import de from 'hyphenated-de';

function BingoItem(props) {
  const [highlight, setHighlight] = useState('1px solid black');

  function foo() {
    props.add(props.idx);
    console.log(props.idx + " clicked");
    setHighlight('2px solid red');
  }

  return(
    <div className="bingoItem" style={{ border: highlight }} key={props.idx} onClick={foo}><Hyphenated language={de}>{props.item}</Hyphenated></div>
  )
}


export default BingoItem;