const items = [
    '"... Du bist muted!"',
    '"Hört ihr mich?"',
    '"Seht ihr mich?"',
    '"Ich kann euch nicht hören!"',
    'Mit Firefox in Jitsi',
    'Sitzt mit Schlüpper/BH drin weil Kamera vergessen',
    '"Ich war im falschen Raum"',
    '"Wollen wir nicht lieber XY nehmen?"',
    'Audio fällt aus',
    'Video fällt aus',
    'DSL-Zwangstrennung trennt TN',
    'Kind schreit rein',
    'Person läuft durch das Bild',
    'Teilnehmer will partout kein Video anmachen',
    '"Fehlt noch jemand?"',
    '"Sind alle da?"',
    '"Ist XY im call?"',
    '"Wollen wir anfangen?"',
    '"Danke für die Teilnahme!"',
    '"Warten wir noch ein bisschen."',
    '"Oh sorry, du zuerst"',
    '"Mein nächster Call geht gleich los"',
    '"Habt ihr auch ein Echo?"',
    'TN ist "eingefroren"',
    '"Kannst du mal den Screen sharen?"',
    '"Schreibt wer mit?"',
    '"Lass dazu mal telefonieren"',
    '"Lass uns da nochmal reden"',
    '"Ich muss mal an die Tür"',
    '"Kannst du das rummailen?"',
    '"Lass uns da mal persönlich treffen"',
    'Kommentar zur Einrichtung',
    'Teilnehmer ändert Hintergrundbild',
    'Teilnehmer benutzt Hintergrundweichzeichner',
    'Teilnehmer isst oder trinkt im Video',
    'Off-topic-Gespräch weil noch jemand fehlt',
    'Off-topic-Gespräch nach dem eigentlichen Call',
    'Tierlaute hörbar',
    'Haustier sichtbar',
    '"Ich kann nicht stereo!"',
    '"Bin gleich wieder da!"',
    'Leerer Stuhl',
    '"Dann hätten wir das!"'
];

function getItems(num) {
	let temp, j, i = items.length;
	while (--i) {
		j = ~~(Math.random() * (i+1));
		temp = items[i];
		items[i] = items[j];
		items[j] = temp;
	}
	return items.slice(0,num);
};

module.exports = getItems;
