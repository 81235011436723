import React, { useState } from 'react';
import BingoBoard from './BingoBoard';
import './App.css';
import Modal from 'react-modal';

function App(props) {
  const [impressumOpen, setImpressumOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);

  return (
    <div className="App" id="App">
      <Modal
        appElement={document.getElementById('App')}
        isOpen={impressumOpen}
        contentLabel="Impressum"
        onRequestClose={() => setImpressumOpen(false)}>
        <h2>Impressum</h2>
        <p>Verantwortlich für alles:</p>
        <address>
          Peter Kornherr<br/>
          Gothaer Str. 23<br/>
          96486 Lautertal<br/>
          Tel. 09561/7088164<br/>
          <a href="mailto:peter@kornherr.IT">peter@kornherr.IT</a>
        </address>
        <h2>Datenschutz</h2>
        <p>Dieser Dienst erfasst keine personenbezogenen oder -beziehbaren Daten, nutzt kein Tracking, keine Personalisierung, keine Auswertung von IP-Adressen. Nix, nada.</p>
        <h2>Input</h2>
        <p>Falls Du mehr/neue Punkte für das Bingo einwerfen möchtest: Kontaktiere mich per E-Mail.</p>
        <h2>custom hosting</h2>
        <p>Wenn Du eine eigene Instanz des Bingos haben möchtest: Die Quellen sind derzeit nicht-offentlich im Git-Repository der TechGenossen eG. Gerne kannst Du aber eine Kopie haben, kontaktiere mich bitte per E-Mail.</p>
        <h2>Einbindung</h2>
        <p>Wenn Du das Bingo auf eigene Seiten einbinden möchtest, mach das halt. Es gibt noch keine Option meinen Header auszublenden, CSS ist Dein Freund.</p>
        <button className="closebutton" onClick={() => setImpressumOpen(false)}>X</button>
      </Modal>
      <Modal
        appElement={document.getElementById('App')}
        isOpen={aboutOpen}
        contentLabel="About"
        onRequestClose={() => setAboutOpen(false)}>
          <h2>Entstehung</h2>
          <p>Bei einem gemütlichen Weekly-Treff der <a href="https://techgenossen.de" target="_blank" rel="noopener noreferrer">TechGenossen eG</a>, Covid-19-bedingt in unserem <a href="https://meet.techgenossen.de" target="_blank" rel="noopener noreferrer">jitsi</a>, beim üblichen Herumblödeln kam halt die Idee, das <a href="https://de.wikipedia.org/wiki/Buzzword-Bingo" target="_blank" rel="noopener noreferrer">Bullshitbingo</a> für Videokonferenzen zu adaptieren. Da ich gerade Lust hatte mal etwas <a href="https://reactjs.org/" _target="blank" rel="noopener noreferrer">react</a> zu üben ist dann **POOF** der Dienst gespawned.</p>
          <p>Da ich derzeit auch gerne mit meinem <a href="https://blog.0x2a.work" target="_blank" rel="noopener noreferrer">kubernetes-Cluster</a> herumspiele ist das natürlich auch dort deployed.</p>
          <p>Der Dienst hat kein(e) SLA ;-)</p>
          <p>Trivia: Livegang war an meinem 4. Hochzeitstag. Lasst also in der Nutzung etwas Ehrfurcht walten!</p>
          <h2>Neue Items</h2>
          <p>Wenn Du lustige oder gute Ideen bzw Vorschläge für weitere Optionen für das Bingo hast, schicke mir doch eine <a href="mailto:peter@kornherr.IT">E-Mail</a>. Ich freue mich auf schönen Input.</p>
          <p>Auch andere Vorschläge (derzeit denke ich über Sound nach. Sinnvoll?) betrachte ich idR wohlwollend ;-)</p>
        <button className="closebutton" onClick={() => setAboutOpen(false)}>X</button>
      </Modal>
      <header className="App-header">
        <div className="navbar" >
          <span className="index navitem"><a href="/">Konfibingo</a></span>
          <span className="impressum navitem" onClick={() => setImpressumOpen(true)}>Impressum</span>
          <span className="about navitem" onClick={() => setAboutOpen(true)}>About</span>

        </div>
      </header>
      <section id="board">
        <BingoBoard bingoItemValues = { props.bingoItemValues } />
      </section>
    </div>
  );
}

export default App;
